import React from "react";
//import logo from "./logo.svg";
import "./App.css";
import Header from "./container/header";
import Home from "./container/home";
import Products from "./components/products";
import Forceatmmachine from "./components/product/force-atm-machine";
import Tezztatmmachine from "./components/product/tezzt-atm-machine";
import Haloatmmachine from "./components/product/haloII-atm-machine";
import Ftzzseatmmachine from "./components/product/ftzzse-atm-machine";
import Fthreezzseatmmachine from "./components/product/fthreezzse-atm-machine";
import Mxfthreezzatmmachine from "./components/product/mxfthreezz-atm-machine";
import Fzzzwatmmachine from "./components/product/fzzzw-atm-machine";
import Gtfzzatmmachine from "./components/product/gtfzz-atm-machine";
import Onyxatmmachine from "./components/product/onyx-atm-machine";
import Gttzzzatmmachine from "./components/product/gttzzz-atm-machine";
import Cszzzatmmachine from "./components/product/cszzz-atm-machine";
import FAQ from "./components/faq";
import ContactUs from "./components/contactUs";
import Footer from "./container/footer";
import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Header />
      <Route component={App}
    path="/"
    onChange={(prevState, nextState) => {
      if (nextState.location.action !== "POP") {
        window.scrollTo(0, 0);
      }
    }}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/product/force-atm-machine" component={Forceatmmachine} />
          <Route exact path="/product/2800T" component={Tezztatmmachine} />
          <Route exact path="/product/haloII-atm-machine" component={Haloatmmachine} />
          <Route exact path="/product/5200SE" component={Ftzzseatmmachine} />
          <Route exact path="/product/5300SE" component={Fthreezzseatmmachine} />
          <Route exact path="/product/MX5300" component={Mxfthreezzatmmachine} />
          <Route exact path="/product/4000W" component={Fzzzwatmmachine} />
          <Route exact path="/product/G2500" component={Gtfzzatmmachine} />
          <Route exact path="/product/Onyx" component={Onyxatmmachine} />
          <Route exact path="/product/GT3000" component={Gttzzzatmmachine} />
          <Route exact path="/product/C6000" component={Cszzzatmmachine} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/contactus" component={ContactUs} />
        </Switch>
      </Route>
      <Footer />
    </div>
  );
}

export default App;
