import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../Styles/productstyle.scss";
import "../../Styles/productdetailstyle.scss";
import fthreezzseatm from "../../assets/images/products/5300CE.png";
import arrowImg from "../../assets/images/right-arrow.svg";

import fthreezzseatmPDF from "../../assets/images/products/brochure/5300SE-V2.pdf";

class fthreezzseatmmachine extends Component {
  render() {
    return (
      <div>
      <div id="section-products" className="section-products my-md-4 py-md-4 my-5 py-5 bgshape">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 grow-left">
              <span className="text-uppercase d-block f-16">
              Your High-Capacity, High-Volume ATM Solution With 24/7 Access
              </span>
              <h3 className="f-36 text-gray">
              5300SE
              </h3>
              <div className="f-16 mt-3">
              The 5300SE delivers the powerful financial transaction support you need with a Windows CE platform base. This high-volume ATM solution features high-capacity note storage and 24/7 customer access. This rugged model brings you the same high quality and proven dependability you expect from industry leader Nautilus Hyosung.
              </div>
              <div className="mt-3 mt-md-3">
                <a href={fthreezzseatmPDF} rel="noopener noreferrer" target="_blank" className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn">Brochure</a>
                <Link  className="d-inline-block f-18 view-btn" to="/products">View All ATMs  <img alt="arrow" src={arrowImg} /></Link>
              </div>
            </div>
            <div className="col-12 col-md-6 grow-right text-center">
              <img alt="" src={fthreezzseatm} className="product-detail-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-specification my-3 py-3">
        <div className="container">
          <h2>Specifications</h2>
          <span className="sep"></span>
          <div className="spec-wrap">
            <div className="specs-single">
                <div className="specs-name"> Dimension:</div>
                <div className="specs-desc">
                  <p>Height: 54.4”<br /> Width: 18.1”<br /> Depth: 25.4”<br /> Weight: 617 lbs.</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Environmental Conditions:</div>
                <div className="specs-desc">
                  <p>Temperature: 0°C ~ 40°C ( 28°F ~ 104°F )<br /> Humidity: 20% - 85%</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> System Platform:</div>
                <div className="specs-desc"> Microsoft® Windows® CE 6.0</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Security:</div>
                <div className="specs-desc">
                  <p>UL 291 Business Hours Safe, Electronic Lock, KABA Mas Cencon Lock, Level1 Safe, Dial Lock, S&amp;G Lock</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Printers:</div>
                <div className="specs-desc"> Graphical Thermal Receipt Printer</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Communication:</div>
                <div className="specs-desc"> TCP/IP, Dial-up</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Screen Display:</div>
                <div className="specs-desc"> 15” color TFT LCD, Privacy filter</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Input Type:</div>
                <div className="specs-desc"> Option RKT, PCI EPP (Encrypting Pin Pad), ADA Compliant, 4 x 2 function keys</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Card Reader:</div>
                <div className="specs-desc"> IC Card Reader (optional), Dip type card reader</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Cash Dispenser:</div>
                <div className="specs-desc"> 2,000 Notes Cassette, Up to 8,000 notes (optional) upgradable to four cassettes</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Power Supply:</div>
                <div className="specs-desc"> AC 110-240V, 50-60 Hz</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Additional Features:</div>
                <div className="specs-desc">
                  <p>Audio Jack, Speaker, Flicker, EPP illumination, Braille keypad, Weatherized (water proof), Electronic Journal, Digital Receipt, Enhanced Standard 1 (Hyosung Standard), Retractable door handle, Topper, Supports three languages: English, Spanish, French</p>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default fthreezzseatmmachine;