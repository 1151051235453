import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../Styles/productstyle.scss";
import "../../Styles/productdetailstyle.scss";
import onyxatm from "../../assets/images/products/Onyx.png";
import arrowImg from "../../assets/images/right-arrow.svg";

import onyxatmPDF from "../../assets/images/products/brochure/Genmega_Onyx_Brochure.pdf";

class onyxatmmachine extends Component {
  render() {
    return (
      <div>
      <div id="section-products" className="section-products my-md-4 py-md-4 my-5 py-5 bgshape">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 grow-left">
              <span className="text-uppercase d-block f-16">
              The Future of ATMs in a Sleek, Upscale Package
              </span>
              <h3 className="f-36 text-gray">
              Onyx
              </h3>
              <div className="f-16 mt-3">
              Boasting a 10.2 wide screen, or an optional 12” touch screen, a security reflective front, and touch keypad, the Onyx provides the future of ATM technology. The strong cabinet, tough exterior and security enhancements, however, make it durable enough for any location. An optional 3” printer upgrade allows for graphics and couponing to complete a full ATM product offering.
              </div>
              <div className="mt-3 mt-md-3">
                <a href={onyxatmPDF} rel="noopener noreferrer" target="_blank" className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn">Brochure</a>
                <Link  className="d-inline-block f-18 view-btn" to="/products">View All ATMs  <img alt="arrow" src={arrowImg} /></Link>
              </div>
            </div>
            <div className="col-12 col-md-6 grow-right text-center">
              <img alt="" src={onyxatm} className="product-detail-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-specification my-3 py-3">
        <div className="container">
          <h2>Specifications</h2>
          <span className="sep"></span>
          <div className="spec-wrap">
            <div className="specs-single">
                <div className="specs-name"> Topper:</div>
                <div className="specs-desc">
                    <p>
                    Optional LED Topper Sign<br/>
Can accept custom inserts
                    </p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Integrated Illuminated Sign:</div>
                <div className="specs-desc">
                    <p>High Visiblility LED Sign<br/>Attract Customers</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> LCD Screen:</div>
                <div className="specs-desc"> 
                10.2 inch wide 32-bit color<br/>
                Backlit TFT LCD panel<br/>
                1280x800 Resolution<br/>
                12 inch touch-screen (Optional)<br/>
                Customizable Ad Screens
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Printer:</div>
                <div className="specs-desc">
                    <p>
                    56mm (2" standard)<br/>
                    80mm (3" optional)<br/>
                    Graphics Capable with 3"
                    </p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> PIN Entry Device:</div>
                <div className="specs-desc">
                    <p>
                    16-key alphanumeric keypad<br/>
                    PCI Certified<br/>
                    Triple-DES (TDES) compliant EPP
                    </p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Communication:</div>
                <div className="specs-desc">
                    <p>
                    56Kb Internal Model<br/>
                    TCP/IP Ethernet - SSL
                    </p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> CPU:</div>
                <div className="specs-desc">
                    <p>
                    Cortex-A8 CPU (800MHz)<br/>
                    DDR2 256MB SRAM memory<br/>
                    512MB Flash Memory<br/>
                    Windows CE 6.0
                    </p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Card Reader:</div>
                <div className="specs-desc">
                    <p>
                    DIP Type card reader<br/>
                    EMV Optional
                    </p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> ADA:</div>
                <div className="specs-desc">
                    <p>
                    Voice Guidance<br/>
                    Lighted Transaction Guidance<br/>
                    Accessible Keypad Layout
                    </p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Security:</div>
                <div className="specs-desc">
                    <p>
                    UL291 Business Hours Vault<br/>
                    Dial or Electronic Lock
                    </p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Cash Dispenser:</div>
                <div className="specs-desc">
                    <p>
                    1000 Note Removable Cassette (SCDU)<br/>
                    Up to 4 x 2000 Note Removable Cassette (HCDU)
                    </p>
                </div>
            </div>
            </div>
        </div>
      </div>
      </div>
    );
  }
}

export default onyxatmmachine;