import React, { Component } from "react";
class Tabs extends Component {
  constructor(props) {
    super();
    this.state = {
      active: 0
    }
  }
  
  select = (i) => {
    let _this = this;
    return function() {
      _this.setState({
        active: i
      });
    }
  }
  
  renderTabs = () => {
    return React.Children.map(this.props.children, (item, i) => {
      if (i%2 === 0) {
        let active = this.state.active === i ? 'active' : '';
        return <button onClick={this.select(i)} className={`${active} tab`}>{item}<span className="arrow-down"></span></button>;
      }
    });
  }
  
  renderContent() {
    return React.Children.map(this.props.children, (item, i) => {
      if (i-1 === this.state.active) {
        return <div className='content'>{item}</div>;
      } else {
        return;
      }
    });
  }
  
  render() {
    return (
      <div className="tabs">
        <div className="tabHeader">
            <div className="tabHeaderScroll">{this.renderTabs()}</div>
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

export default Tabs;