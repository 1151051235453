import React, { Component } from "react";

import addrevImg from "../assets/images/libertyImgs/additional-revenue.png";
import freeImg from "../assets/images/libertyImgs/free-atm.png";
import freerepImg from "../assets/images/libertyImgs/free-replace.png";
import freeservImg from "../assets/images/libertyImgs/free-services.png";
import hassleImg from "../assets/images/libertyImgs/hassle-soft.png";
import lowcostImg from "../assets/images/libertyImgs/lower-cost.png";
import moniserImg from "../assets/images/libertyImgs/moni-services.png";
import nohidImg from "../assets/images/libertyImgs/no-hidden.png";
import serplanImg from "../assets/images/libertyImgs/service-plan.png";
import warranImg from "../assets/images/libertyImgs/warranties.png";
import signageImg from "../assets/images/libertyImgs/signage-new.svg";
import freepayrollImg from "../assets/images/libertyImgs/free-pay-roll-new.svg";

class chooseLiberty extends Component {
  render() {
    return (
      <div className="section-choose-liberty my-5 py-xl-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-center">
              <span className="d-block f-14">WHY</span>
              <h2>Why Choose Liberty?</h2>
              <p className="f-16">
                When you choose to partner with Liberty Payment & Processing,
                there are never any worries, never any upfront fees or
                additional charges, and never any questions as to how we can
                help.
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 liberty-blocks text-center">
              <div className="row justify-content-center">
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                  <img alt="" src={lowcostImg} />
                  <p className="f-14 my-3">Lower Equipment Costs</p>
                </div>
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                  <img alt="" src={hassleImg} />
                  <p className="f-14 my-3">Hassle-Free ATM Software</p>
                </div>
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                  <img alt="" src={freerepImg} />
                  <p className="f-14 my-3">Free Replacement Parts</p>
                </div>
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                  <img alt="" src={freeImg} />
                  <p className="f-14 my-3">Free ATM Replacements</p>
                </div>
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                  <img alt="" src={freeservImg} />
                  <p className="f-14 my-3">Free Services</p>
                </div>
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                  <img alt="" src={freepayrollImg} width="70" />
                  {/*<img alt="" src={nohidImg} />*/}
                  <p className="f-14 my-3">Free Paper Roll</p>
                </div>
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                  <img alt="" src={nohidImg} />
                  <p className="f-14 my-3">No Hidden Fees</p>
                </div>
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                  <img alt="" src={moniserImg} />
                  <p className="f-14 my-3">24/7 Monitoring Services</p>
                </div>
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                  <img alt="" src={serplanImg} />
                  <p className="f-14 my-3">
                    Comprehensive{" "}
                    <span className="d-block">ATM Service Plans</span>
                  </p>
                </div>
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                  <img alt="" src={addrevImg} />
                  <p className="f-14 my-3">
                    On-Screen Advertisements{" "}
                    <span className="d-block">For Additional Revenue</span>
                  </p>
                </div>
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                  <img alt="" src={warranImg} />
                  <p className="f-14 my-3">
                    Two Year Warranties{" "}
                    <span className="d-block">(New ATMs Only)</span>
                  </p>
                </div>
                <div className="col-6 col-md-2 text-center py-3 align-self-end">
                <img alt="" src={signageImg} width="55" />
                  <p className="f-14 my-3 align-self-end">
                    Free Signage{" "}
                    <span className="d-block">To Draw Foot Traffic</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default chooseLiberty;
