import React, { Component } from "react";

import locationImg from "../assets/images/footerImgs/location.png";
import callImg from "../assets/images/footerImgs/call.png";
import emailImg from "../assets/images/footerImgs/email.png";

class footer extends Component {
  render() {
    return (
      <div id="section-footer" className="section-footer py-3 py-md-5">
        <div className="container pt-xl-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-9 text-center">
              <h4 className="f-36">Contact Us Today To Get Started</h4>
              {/*
              <p className="f-20">Subscribe to our newsletter to receive the latest news.</p>
              <form>
                <input
                  type="name"
                  className="form-control d-inline-block w-50 mr-3"
                  id="inputPassword2"
                  placeholder="Enter your email address"
                />
                <button
                  type="submit"
                  className="btn text-white d-inline-block"
                >
                  Subscribe
                </button>
              </form>
                */}
              <p className="py-3 py-md-4 text-left">
                If you’re ready to partner with Liberty Payment & Processing,
                contact us today and let’s get started. We’d love to talk more
                with you about what we can do to help you increase your revenue.
                Whether it be an ATM or a new payment and processing system, our
                team of experts is always standing at the ready to help you
                succeed.
              </p>
              <div className="contact text-left py-5">
                <div className="row align-items-center">
                  <div className="col-12 col-md-4 mb-2">
                    <div className="media">
                      <img
                        className="align-self-center mr-3"
                        src={locationImg}
                        alt="location"
                      />
                      <div className="media-body">
                        <p className="m-0">
                          2602 Eric Land Suite{" "}
                          <span className="d-block">
                            E1 Burlington NC 27215
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-4 mb-2">
                    <div className="media">
                      <img
                        className="align-self-center mr-3"
                        src={callImg}
                        alt="location"
                      />
                      <div className="media-body">
                        <p className="m-0">
                          <a href="tel:1 833 333 5286">1 833 333 5286 </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-4 mb-2">
                    <div className="media">
                      <img
                        className="align-self-center mr-3"
                        src={emailImg}
                        alt="location"
                      />
                      <div className="media-body">
                        <p className="m-0">
                          <a href="mailto:libertypandp@gmail.com">
                            libertypandp@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 copy-right pt-3 pt-xl-5">
              <div className="row">
                <div className="col-12 col-md-6 text-center text-lg-left">
                  <p className="m-0 f-14">
                    Copyright © 2019, Liberty Payment and Processing. All Rights
                    Reserved.
                  </p>
                </div>
                <div className="col-12 col-md-6 text-center text-lg-right">
                  <a className="d-inline-block f-14" href="/">
                    Privacy<span className="d-inline-block mx-2">|</span>
                  </a>
                  <a className="d-inline-block f-14" href="/">
                    Terms Of Use
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default footer;
