import React, { Component } from "react";

class faq extends Component {
  render() {
    return (
      <div className="section-faq py-5 py-md-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-5 pt-md-1">
              <h2 className="d-block mb-3 mb-md-4">FAQs</h2>
              <p>
                If you have a question about Liberty Payment & Processing,
                you’ve come to the right place. Please take a look at our
                frequently asked questions to see if we can help. If you require
                some more assistance, contact us today and speak to one of our
                customer service representatives. They’d be more than happy to
                do everything they can to help.
              </p>

              <span className="d-block txt-grn f-20">
                Are your ATMs available for purchase or for rent?
              </span>
              <p>
                We offer both options for our valued clients. When you first
                contact us, we’ll work with you t o determine the appropriate
                budget for your needs.
              </p>

              <span className="d-block txt-grn f-20">
                How long will you service an ATM that I purchase for my
                business?
              </span>
              <p>
                All purchases made through Liberty Payment & Processing comes
                with a two year warranty for all new ATMs. Throughout the
                lifespan of the machine, we will provide all basic maintenance
                and servicing free of charge.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default faq;
