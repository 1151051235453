import React, { Component } from "react";

class contactUs extends Component {
  render() {
    return (
      <div className="section-contact-us py-5 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-3 pt-md-5">
              <h2 className="d-block">Contact Us Today To Get Started</h2>
              <p className="py-3 py-md-4">
                If you’re ready to partner with Liberty Payment & Processing,
                contact us today and let’s get started. We’d love to talk more
                with you about what we can do to help you increase your revenue.
                Whether it be an ATM or a new payment and processing system, our
                team of experts is always standing at the ready to help you
                succeed.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default contactUs;
