import React, { Component } from "react";
import Tabs from "../container/Tabs";

class services extends Component {
  render() {
    return (
      <div className="section-pay-process py-3 py-md-5">
        <div className="container py-md-3">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-center">
              <h6 className="text-uppercase">Services</h6>
              <h2>Liberty Payment & Processing</h2>
              <p className="f-18">
                When it comes to your business, nothing is more important than
                driving foot traffic through your door, adding additional
                revenue, and reducing operational expenses to ensure that you
                maximize your profits.
              </p>
            </div>
            <div className="col-12 services-blocks py-5">
              <div className="row">
                <Tabs>
                  Free ATM Placement
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="service-card-body bg-five">
                          <div className="atm-overlay" />
                          <div className="service-card-content">
                            <p className="f-20 text-white">Free ATM Placement</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-8 mb-8">
                        <span className="f-18 text-left">
                          {/*<h4>Free ATM Placement</h4>*/}
                          <p>Interested in getting an ATM but not interested in outlaying the initial capital? A Free ATM Placement or Merchant Assisted ATM may be the best fit for your needs.</p>
                          <p>Full-Service ATM Placement- Liberty Payment & Processing provides the ATM, ATM processing, vault cash management, as well as services and repairs the ATM so you can benefit from the transactions hassle free. Liberty Payment & Processing pays you a percentage of the surcharge income. Available for qualified locations only.</p>
                          <p>Merchant Assisted ATM Placement- Liberty Payment & Processing provides the ATM, ATM processing as well as service and repairs- the merchant provides the vault cash. So no upfront investing in hardware, service headaches, upgrades, only manage the vault. Available for qualified locations only. This option is more profitable for the merchant.</p>
                          <h4>Free ATM Placement Benefits</h4>
                          <p>
                          Hassel Free Profitable ATM solution
                          Little to No Capital Outlay
                          Highly Competitive Commission Structure
                          Dedicated Account Manager
                          24/7/365 Support
                          Financial Institution Branding Capable
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                  Buy or Lease an ATM
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="service-card-body bg-one">
                          <div className="atm-overlay" />
                          <div className="service-card-content">
                            <p className="f-20 text-white">Buy or Lease an ATM</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-8 mb-8">
                        <span className="f-18 text-left">
                          {/*<h4>Free ATM Placement</h4>*/}
                          <h4>Buy an ATM</h4>
                          <p>Buy an ATM Machine for your business today! Thinking about buying an ATM Machine? Ever wonder why ATMs are everywhere? Very simply put- account holders demand them. You too can capitalize on their success and buy an ATM for your business. What’s even better- there has never been a better time to buy an ATM machine and ATMs have never been more affordable. </p>
                          <p><b style={{color:'#67a07d'}}>All New ATM comes with FREE installation and 2 years warranty.</b></p>
                          <h4>Lease an ATM</h4>
                          <p>Considering Leasing an ATM for your business? When you Lease an ATM, you get the same benefits as if you own the ATM, without upfront expense. For a low monthly ATM lease payment, you can lease an ATM with little to no money down. At the end of the lease term, you will own the ATM. A typical lease term is one to three years.</p>
                          <p>When you buy or lease an ATM machine from liberty Payment & Processing you will:</p>
                          <ul>
                            <li>Reduce or Eliminate Credit card fees</li>
                            <li>Increase foot traffic</li>
                            <li>Boost in-store sales</li>
                            <li>generate additional revenue</li>
                          </ul>
                        </span>
                      </div>
                    </div>
                  </div>
                  ATM Processing
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="service-card-body bg-two">
                          <div className="atm-overlay" />
                          <div className="service-card-content">
                            <p className="f-20 text-white">ATM Processing</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-8 mb-8">
                        <span className="f-18 text-left">
                          {/*<h4>Free ATM Placement</h4>*/}
                          <p>If you’re looking for a company to set up transaction processing services for your ATM, you’ve come to the right place. Liberty Payment & Processing is registered to process transactions with all major national and regional banking networks including MasterCard, Visa, Discover, American Express, Maestro, Plus, Star, Pulse, Cirrus, Armed Forces Network, Exchange, CU24 as well as all State EBT programs that supports ATM withdrawals.</p>
                          <p>Whether you have a brand new ATM and or reprogramming your own ATM, we’ll have an ATM technician reprogram your ATM  with our processing at NO cost to you. There’s no charge for the setup.</p>
                          <p><span style={{color:'#67a07d'}}>Free ATM Web Access</span> via any computer or your smartphone. Efficiently manage your ATM or ATMs with our free real-time web reporting. View ATM machine balances, download reports, get text and email alerts with our simple, user friendly ATM terminal management system</p>
                          <p>Receive 100% Of The Surcharge</p>
                          <p>Liberty Payment & Processing Llc, processing customers will receive 100% of their ATM surcharge revenue. If you set a surcharge fee at $2.50, you keep that entire $2.50.</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  ATM Service & Support
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="service-card-body bg-three">
                          <div className="atm-overlay" />
                          <div className="service-card-content">
                            <p className="f-20 text-white">ATM Service & Support</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-8 mb-8">
                        <span className="f-18 text-left">
                          <h4>ATM Service & Support</h4>
                          <p>Anyone can sell you an ATM. It’s what happens after the sale that really matters most- ATM Service and Support. Local and Dependable service is what sets Best apart from the rest.</p>
                          <p>Toll-Free Help Desk answered by a live person. When a field visit is needed for your ATM, we will deploy a tech immediately to get the job done. Daily terminal activity monitoring and tracking and follow up phone call when necessary Tailored On Site Service Plans</p>
                          <p>Even if you haven’t purchased your ATM from Liberty Payment 7 processing, we are happy to help you in a crunch.</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  Credit Card Processing
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-4 mb-4">
                        <div className="service-card-body bg-four">
                          <div className="atm-overlay" />
                          <div className="service-card-content">
                            <p className="f-20 text-white">Credit Card Processing</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-8 mb-8">
                        <span className="f-18 text-left">
                          <h4>Credit Card Processing</h4>
                          <p>Liberty Payment and Processing team works directly with our merchants to find the best processing solution for your business. We are a customer services first processor to ensure satisfaction.</p>
                          <p>Liberty Payment will evaluate your statements for free and return with a side by side professional proposal. Therefore, you can see the differences and savings. The proposal includes new rates, monthly and annual savings. Liberty Payment guarantees to stand by the provided proposal.</p>                          
                        </span>
                      </div>
                    </div>
                  </div>
                </Tabs>                 
              </div>
            </div>  
            <div className="col-12 col-md-9 mb-3 text-center">
              <h2>Why Liberty Payment and Processing Is Right for You?</h2>
            </div>
            <div className="col-12 col-md-10">
              <div className="row">
                <div className="col-12 col-md-6">
                  <ul className="pl-4">
                    <li>Consolidated Payment Provider</li>
                    <li>Affordable Rates to Accept Credit Cards</li>
                    <li>Easy-to-Use Secure Technology</li>
                    <li>EMV Compliance</li>
                    <li>Surcharging</li>
                    <li>Large Ticket Processing</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <ul className="pl-4">
                    <li>E-commerce</li>
                    <li>Batch Credit Card Processing</li>
                    <li>Mobile Payments</li>
                    <li>POS systems</li>
                    <li>Pay at the Pump</li>
                  </ul>
                </div>
              </div>
            </div>
            {/*
            <div className="col-12 services-blocks py-5">
              <div className="row">
                <div className="col-12 col-md-4 mb-4">
                  <div className="service-card-body bg-one mb-2">
                    <div className="atm-overlay" />
                    <div className="service-card-content">
                      <p className="f-28 text-white">ATM Installation</p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 mb-4">
                  <div className="service-card-body bg-two mb-2">
                    <div className="atm-overlay" />
                    <div className="service-card-content">
                      <p className="f-28 text-white">ATM Processing</p>
                      <p class="f-14 text-white">Interested in getting an ATM but not interested in outlaying the initial capital? A Free ATM Placement or Merchant Assisted ATM may be the best fit for your needs.</p>
                      <p class="f-14 text-white">Full-Service ATM Placement- Best Products provides the ATM, ATM processing, vault cash management, as well as services and repairs the ATM so you can benefit from the transactions hassle free. Best Products pays you a percentage of the surcharge income. Available for qualified locations only.</p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 mb-4">
                  <div className="service-card-body bg-three mb-2">
                    <div className="atm-overlay" />
                    <div className="service-card-content">
                      <p className="f-28 text-white">ATM Repairs</p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 mb-4">
                  <div className="service-card-body bg-four">
                    <div className="atm-overlay" />
                    <div className="service-card-content">
                      <p className="f-28 text-white">Credit Card Processing</p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 mb-4">
                  <div className="service-card-body bg-five">
                    <div className="atm-overlay" />
                    <div className="service-card-content">
                      <p className="f-28 text-white">Free ATM Placement</p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 mb-4">
                  <div className="service-card-body bg-card-dark">
                    <div className="atm-overlay" />
                    <div className="service-card-content">
                      <p className="f-28 text-white">And More</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
    );
  }
}

export default services;