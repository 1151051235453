import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../Styles/productstyle.scss";
import "../../Styles/productdetailstyle.scss";
import fzzzwatm from "../../assets/images/products/4000W.png";
import arrowImg from "../../assets/images/right-arrow.svg";

import fzzzwatmPDF from "../../assets/images/products/brochure/4000W-1.pdf";

class fzzzwatmmachine extends Component {
  render() {
    return (
      <div>
      <div id="section-products" className="section-products my-md-4 py-md-4 my-5 py-5 bgshape">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 grow-left">
              <span className="text-uppercase d-block f-16">
              Proof Positive That Innovation Comes In Small Packages
              </span>
              <h3 className="f-36 text-gray">
              4000W
              </h3>
              <div className="f-16 mt-3">
              The 4000W provides unsurpassed convenience to all user interface through an 8-inch color LCD screen. The 4000W was designed with speed and ease-of-use in mind so you get rapid transaction processing and speedy service.
              </div>
              <div className="mt-3 mt-md-3">
                <a href={fzzzwatmPDF} rel="noopener noreferrer" target="_blank" className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn">Brochure</a>
                <Link  className="d-inline-block f-18 view-btn" to="/products">View All ATMs  <img alt="arrow" src={arrowImg} /></Link>
              </div>
            </div>
            <div className="col-12 col-md-6 grow-right text-center">
              <img alt="" src={fzzzwatm} className="product-detail-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-specification my-3 py-3">
        <div className="container">
          <h2>Specifications</h2>
          <span className="sep"></span>
          <div className="spec-wrap">
            <div className="specs-single">
                <div className="specs-name"> Dimension:</div>
                <div className="specs-desc">
                    <p>Height: 18.8” (478 mm), Width: 25.3” (643 mm), Depth: 8.7” (222 mm), Weight: 110 lbs. (50 kg)</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Environmental Conditions:</div>
                <div className="specs-desc">
                    <p>Humidity: 20%-85%, Temperature: 5°C-35°C (41°F -95°F)</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> System Platform:</div>
                <div className="specs-desc"> Microsoft® Window® CE6.0</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Security:</div>
                <div className="specs-desc">
                    <p>Electronic Lock</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Printers:</div>
                <div className="specs-desc"> 2” Graphical thermal receipt printer</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Communication:</div>
                <div className="specs-desc"> TCP/IP, Dial-up</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Screen Display:</div>
                <div className="specs-desc"> 8” color TFT LCD</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Input Type:</div>
                <div className="specs-desc"> Option RKT, 8 function keys, PCI compliant EPP</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Card Reader:</div>
                <div className="specs-desc"> IC Card Reader (optional), Anti-skimming (optional), Dip type card reader</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Cash Dispenser:</div>
                <div className="specs-desc"> 500 note cassette, 20 note reject box, Cash tray illumination</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Power Supply:</div>
                <div className="specs-desc"> AC 110-240V, 50-60 Hz</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Additional Features:</div>
                <div className="specs-desc">
                    <p>Audio Jack, Speaker, EPP illumination, Braille keypad, Supports six languages: English, French, Japanese, Chinese, and Korean, Electronic Journal, Digital Receipt, Enhanced Standard 1 (Hyosung Standard), DCC (Dynamic Currency Conversion), Retractable door handle</p>
                </div>
            </div>
            </div>
        </div>
      </div>
      </div>
    );
  }
}

export default fzzzwatmmachine;