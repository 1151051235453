import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../Styles/productstyle.scss";
import arrowImg from "../assets/images/right-arrow.svg";
import forceatm from "../assets/images/products/force-new-atm.png";
import forceatmPDF from "../assets/images/products/brochure/MX2800SE_FORCE-1.pdf";
import tezzTatm from "../assets/images/products/2800T.png";
import tezzTatmPDF from "../assets/images/products/brochure/2800T-1-1.pdf";
import haloatm from "../assets/images/products/halo-atm-machine.png";
import haloatmPDF from "../assets/images/products/brochure/Halo-II-Updated.pdf";
import seatm from "../assets/images/products/5200SE.png";
import seatmPDF from "../assets/images/products/brochure/5200SE-2-2.pdf";
import ftceatm from "../assets/images/products/5300CE.png";
import ftceatmPDF from "../assets/images/products/brochure/5300SE-V2.pdf";
import mx5300atm from "../assets/images/products/MX5300.png";
import mx5300atmPDF from "../assets/images/products/brochure/5300-1.pdf";
import FTWatm from "../assets/images/products/4000W.png";
import FTWatmPDF from "../assets/images/products/brochure/4000W-1.pdf";
import G2500 from "../assets/images/products/G2500.png";
import G2500PDF from "../assets/images/products/brochure/Genmega_G2500_Brochure.pdf";
import Onyx from "../assets/images/products/Onyx.png";
import OnyxPDF from "../assets/images/products/brochure/Genmega_Onyx_Brochure.pdf";
import GT3000 from "../assets/images/products/GT3000.png";
import GT3000PDF from "../assets/images/products/brochure/Genmega_GT3000_Brochure.pdf";
import C6000 from "../assets/images/products/C6000.png";
import C6000PDF from "../assets/images/products/brochure/Genmega_C6000_Brochure.pdf";
import DOWNLOADSVG from "../assets/images/products/download-solid.svg";

class products extends Component {
  render() {
    return (
      <div className="section-products py-5 py-md-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-5 pt-md-1">
              <h2 className="d-block mb-3 mb-md-4">Products</h2>
              <p>
                <span>Liberty Payment & Processing </span>carries a wide variety
                of high quality ATM machines that use the most advanced payment
                processing software to offer a seamless transaction experience
                for our clients and their customers
              </p>
              <p>Take a look down below at our various ATM machines:</p>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="product-blk">
                    <div className="product-img">
                      <img alt="" src={forceatm} />
                    </div>
                    <div className="product-title mt-3">
                      <span className="d-block txt-blk f-20">FORCE</span>
                    </div>
                    <div className="product-btn-blk mt-2 mt-md-2">
                      <a href={forceatmPDF} rel="noopener noreferrer" target="_blank" className="f-18 download-btn">
                        <img alt="" src={DOWNLOADSVG} className="svg" />
                        <span className="download-brochure">Brochure</span>
                      </a>
                      <Link
                        className="d-inline-block f-18 view-btn"
                        to="/product/force-atm-machine#section-products"
                      >
                        View Details <img alt="arrow" src={arrowImg} />
                      </Link>
                      {/*<a href="/product/force-atm-machine" className="d-inline-block f-18 view-btn">View Details <img alt="arrow" src={arrowImg} /></a>*/}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="product-blk">
                    <div className="product-img">
                      <img alt="" src={tezzTatm} />
                    </div>
                    <div className="product-title mt-3">
                      <span className="d-block txt-blk f-20">2800T</span>
                    </div>
                    <div className="product-btn-blk mt-2 mt-md-2">
                      <a href={tezzTatmPDF} rel="noopener noreferrer" target="_blank" className="f-18 download-btn">
                        <img alt="" src={DOWNLOADSVG} className="svg" />
                        <span className="download-brochure">Brochure</span>
                      </a>
                      <Link
                        className="d-inline-block f-18 view-btn"
                        to="/product/2800T#section-products"
                      >
                        View Details <img alt="arrow" src={arrowImg} />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="product-blk">
                    <div className="product-img">
                      <img alt="" src={haloatm} />
                    </div>
                    <div className="product-title mt-3">
                      <span className="d-block txt-blk f-20">HALO II</span>
                    </div>
                    <div className="product-btn-blk mt-2 mt-md-2">
                      <a href={haloatmPDF} rel="noopener noreferrer" target="_blank" className="f-18 download-btn">
                        <img alt="" src={DOWNLOADSVG} className="svg" />
                        <span className="download-brochure">Brochure</span>
                      </a>
                      <Link
                        className="d-inline-block f-18 view-btn"
                        to="/product/haloII-atm-machine#section-products"
                      >
                        View Details <img alt="arrow" src={arrowImg} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="product-blk">
                    <div className="product-img">
                      <img alt="" src={seatm} />
                    </div>
                    <div className="product-title mt-3">
                      <span className="d-block txt-blk f-20">5200SE</span>
                    </div>
                    <div className="product-btn-blk mt-2 mt-md-2">
                      <a href={seatmPDF} rel="noopener noreferrer" target="_blank" className="f-18 download-btn">
                        <img alt="" src={DOWNLOADSVG} className="svg" />
                        <span className="download-brochure">Brochure</span>
                      </a>
                      <Link
                        className="d-inline-block f-18 view-btn"
                        to="/product/5200SE#section-products"
                      >
                        View Details <img alt="arrow" src={arrowImg} />
                      </Link>
                      {/*<a href="/#section-footer" className="d-inline-block f-18 view-btn">View Details <img alt="arrow" src={arrowImg} /></a>*/}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="product-blk">
                    <div className="product-img">
                      <img alt="" src={ftceatm} />
                    </div>
                    <div className="product-title mt-3">
                      <span className="d-block txt-blk f-20">5300SE</span>
                    </div>
                    <div className="product-btn-blk mt-2 mt-md-2">
                      <a href={ftceatmPDF} rel="noopener noreferrer" target="_blank" className="f-18 download-btn">
                        <img alt="" src={DOWNLOADSVG} className="svg" />
                        <span className="download-brochure">Brochure</span>
                      </a>
                      <Link
                        className="d-inline-block f-18 view-btn"
                        to="/product/5300SE#section-products"
                      >
                        View Details <img alt="arrow" src={arrowImg} />
                      </Link>
                      {/*<a href="/#section-footer" className="d-inline-block f-18 view-btn">View Details <img alt="arrow" src={arrowImg} /></a>*/}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="product-blk">
                    <div className="product-img">
                      <img alt="" src={mx5300atm} />
                    </div>
                    <div className="product-title mt-3">
                      <span className="d-block txt-blk f-20">MX 5300</span>
                    </div>
                    <div className="product-btn-blk mt-2 mt-md-2">
                      <a href={mx5300atmPDF} rel="noopener noreferrer" target="_blank" className="f-18 download-btn">
                        <img alt="" src={DOWNLOADSVG} className="svg" />
                        <span className="download-brochure">Brochure</span>
                      </a>
                      <Link
                        className="d-inline-block f-18 view-btn"
                        to="/product/MX5300#section-products"
                      >
                        View Details <img alt="arrow" src={arrowImg} />
                      </Link>
                      {/*<a href="/#section-footer" className="d-inline-block f-18 view-btn">View Details <img alt="arrow" src={arrowImg} /></a>*/}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="product-blk">
                    <div className="product-img">
                      <img alt="" src={FTWatm} />
                    </div>
                    <div className="product-title mt-3">
                      <span className="d-block txt-blk f-20">4000W</span>
                    </div>
                    <div className="product-btn-blk mt-2 mt-md-2">
                      <a href={FTWatmPDF} rel="noopener noreferrer" target="_blank" className="f-18 download-btn">
                        <img alt="" src={DOWNLOADSVG} className="svg" />
                        <span className="download-brochure">Brochure</span>
                      </a>
                      <Link
                        className="d-inline-block f-18 view-btn"
                        to="/product/4000W#section-products"
                      >
                        View Details <img alt="arrow" src={arrowImg} />
                      </Link>
                      {/*<a href="/#section-footer" className="d-inline-block f-18 view-btn">View Details <img alt="arrow" src={arrowImg} /></a>*/}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="product-blk">
                    <div className="product-img">
                      <img alt="" src={G2500} />
                    </div>
                    <div className="product-title mt-3">
                      <span className="d-block txt-blk f-20">G2500</span>
                    </div>
                    <div className="product-btn-blk mt-2 mt-md-2">
                      <a href={G2500PDF} rel="noopener noreferrer" target="_blank" className="f-18 download-btn">
                        <img alt="" src={DOWNLOADSVG} className="svg" />
                        <span className="download-brochure">Brochure</span>
                      </a>
                      <Link
                        className="d-inline-block f-18 view-btn"
                        to="/product/G2500#section-products"
                      >
                        View Details <img alt="arrow" src={arrowImg} />
                      </Link>
                      {/*<a href="/#section-footer" className="d-inline-block f-18 view-btn">View Details <img alt="arrow" src={arrowImg} /></a>*/}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="product-blk">
                    <div className="product-img">
                      <img alt="" src={Onyx} />
                    </div>
                    <div className="product-title mt-3">
                      <span className="d-block txt-blk f-20">Onyx</span>
                    </div>
                    <div className="product-btn-blk mt-2 mt-md-2">
                      <a href={OnyxPDF} rel="noopener noreferrer" target="_blank" className="f-18 download-btn">
                        <img alt="" src={DOWNLOADSVG} className="svg" />
                        <span className="download-brochure">Brochure</span>
                      </a>
                      <Link
                        className="d-inline-block f-18 view-btn"
                        to="/product/Onyx#section-products"
                      >
                        View Details <img alt="arrow" src={arrowImg} />
                      </Link>
                      {/*<a href="/#section-footer" className="d-inline-block f-18 view-btn">View Details <img alt="arrow" src={arrowImg} /></a>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="product-blk">
                    <div className="product-img">
                      <img alt="" src={GT3000} />
                    </div>
                    <div className="product-title mt-3">
                      <span className="d-block txt-blk f-20">GT3000</span>
                    </div>
                    <div className="product-btn-blk mt-2 mt-md-2">
                      <a href={GT3000PDF} rel="noopener noreferrer" target="_blank" className="f-18 download-btn">
                        <img alt="" src={DOWNLOADSVG} className="svg" />
                        <span className="download-brochure">Brochure</span>
                      </a>
                      <Link
                        className="d-inline-block f-18 view-btn"
                        to="/product/GT3000#section-products"
                      >
                        View Details <img alt="arrow" src={arrowImg} />
                      </Link>
                      {/*<a href="/#section-footer" className="d-inline-block f-18 view-btn">View Details <img alt="arrow" src={arrowImg} /></a>*/}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="product-blk">
                    <div className="product-img">
                      <img alt="" src={C6000} />
                    </div>
                    <div className="product-title mt-3">
                      <span className="d-block txt-blk f-20">C6000</span>
                    </div>
                    <div className="product-btn-blk mt-2 mt-md-2">
                      <a href={C6000PDF} rel="noopener noreferrer" target="_blank" className="f-18 download-btn">
                        <img alt="" src={DOWNLOADSVG} className="svg" />
                        <span className="download-brochure">Brochure</span>
                      </a>
                      <Link
                        className="d-inline-block f-18 view-btn"
                        to="/product/C6000#section-products"
                      >
                        View Details <img alt="arrow" src={arrowImg} />
                      </Link>
                      {/*<a href="/#section-footer" className="d-inline-block f-18 view-btn">View Details <img alt="arrow" src={arrowImg} /></a>*/}
                    </div>
                  </div>
                </div>
              </div>
              {/*
              <span className="d-block txt-grn f-20">G2500</span>
              <p>
                The Genmega G2500 series ATM is specifically designed for retail
                locations. With all of the necessary payment, processing, and
                transaction features that your customers expect when using an
                ATM, you can even be sure that each machine is configured to
                your own needs – offering you a flexible experience designed to
                benefit your business.
              </p>

              <span className="d-block txt-grn f-20">Onyx</span>
              <p>
                The Genmega Onyx ATM offers a modern and contemporary design
                that speaks luxury. Perfect for upscale nightlife venues,
                restaurants, hotels, clubs, bars, or even gas stations, the Onyx
                ATM offers style and functionality. With the ability to program
                on-screen advertisements or branding, you can use this machine
                to generate additional advertising revenue or to promote your
                own business all at the same time.
              </p>

              <span className="d-block txt-grn f-20">Halo II</span>
              <p>
                The Genmega Halo II is an ATM that features an innovative design
                with a sleek build for those tight spaces. Featuring attractive
                LED lighting that works to draw foot traffic, you can rest
                assured knowing that this unique ATM machine will drive
                additional transactions and revenue to your bottom line, simply
                through its visible features.
              </p>

              <span className="d-block txt-grn f-20">Force</span>
              <p>
                The Hyosung Force ATM is truly a powerful ATM machine – and one
                like no other. Featuring the ultimate user experience, seamless
                serviceability provided by our technicians, fool-proof security
                that thwarts misuse, and unlimited connectivity thanks to
                wireless technology, the Force is truly a force to be reckoned
                with.
              </p>
              */
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default products;
