import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../Styles/productstyle.scss";
import "../../Styles/productdetailstyle.scss";
import tezzteatm from "../../assets/images/products/2800T-atm-machine.png";
import arrowImg from "../../assets/images/right-arrow.svg";

import tezzteatmPDF from "../../assets/images/products/brochure/2800T-1-1.pdf";

class tezztatmmachine extends Component {
  render() {
    return (
      <div>
      <div className="section-products my-md-4 py-md-4 my-5 py-5 bgshape">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 grow-left">
              <span className="text-uppercase d-block f-16">
                A Sleek Walk-Up With Rock Solid Reliability
              </span>
              <h3 className="f-36 text-gray">
                2800T
              </h3>
              <div className="f-16 mt-3">
                The 2800T brings you through-the-wall convenience and the latest advancements in ATM technology. The appealing and weatherized exterior is suitable for even the most high-end locales. This heavy-duty machine gives you dependability and ease of maintenance with the added bonus of high-capacity note storage. You get the same high quality and proven reliability you expect from industry leader Nautilus Hyosung America.
              </div>
              <div className="mt-3 mt-md-3">
                <a href={tezzteatmPDF} rel="noopener noreferrer" target="_blank" className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn">Brochure</a>
                <Link  className="d-inline-block f-18 view-btn" to="/products">View All ATMs  <img alt="arrow" src={arrowImg} /></Link>
              </div>
            </div>
            <div className="col-12 col-md-6 grow-right text-center">
              <img alt="" src={tezzteatm} className="product-detail-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-specification my-3 py-3">
        <div className="container">
          <h2>Specifications</h2>
          <span className="sep"></span>
          <div className="spec-wrap">
            <div className="specs-single">
                <div className="specs-name"> Dimension:</div>
                <div className="specs-desc">
                  <p>Height: 1,828 mm<br /> Width: 400 mm<br /> Depth: 3” wall (670 mm) 10” wall (848 mm)<br /> Weight: 150 kg</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Environmental Conditions:</div>
                <div className="specs-desc">
                  <p>Storage:<br /> - Temperature: 0°C- 40°C (32°F-104°F)<br /> - Humidity: 10- 90%<br /> Operational:<br /> - Temperature: 5°C- 35°C (41°F-95°F)<br /> - Humidity: 25- 85%</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Software:</div>
                <div className="specs-desc">
                  <p>Microsoft® Windows® CE 6.0</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Customer Interface:</div>
                <div className="specs-desc">
                  <p>8” wide TFT LCD, 1024 x 768 resolution, brightness: 240cd/m3</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Media Technology:</div>
                <div className="specs-desc">
                  <p>3 cassettes 2,000 notes each<br /> 40 notes/ transaction<br /> Note by Note reject (200 bills max)</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Security:</div>
                <div className="specs-desc">
                  <p>UL291 business hour safe<br /> UL Level–1 option<br /> Dial lock (option), electronic lock (standard), Cencon lock (option)</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Printers:</div>
                <div className="specs-desc"> 80mm Graphical thermal receipt printer</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Communication:</div>
                <div className="specs-desc"> TCP/IP</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Power Supply:</div>
                <div className="specs-desc"> Input Voltage / Current: 100W Free Voltage (90-264VAC), 90 – 132 VAC / 5.0A; 180 – 264 VAC / 2.5A Frequency: 47 – 63 Hz</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Wall Kit:</div>
                <div className="specs-desc"> 3” - 10” throat</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Additional Information:</div>
                <div className="specs-desc">
                  <p>Weatherized (water proof) Flicker<br /> Illuminated topper<br /> Audio jack<br /> EPP illumination<br /> Speaker<br /> Braille keypad<br /> Electronic journal Digital receipt<br /> Supports six languages; English, Spanish, French, Japanese, Chinese, Korean<br /> Enhanced Standard 1 (Hyosung Standard) DCC (Dynamic Currency Conversion)<br /> Heater (optional)</p>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default tezztatmmachine;