import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../Styles/productstyle.scss";
import "../../Styles/productdetailstyle.scss";
import mxfthreezzatm from "../../assets/images/products/MX5300.png";
import arrowImg from "../../assets/images/right-arrow.svg";

import mxfthreezzatmPDF from "../../assets/images/products/brochure/5300-1.pdf";

class mxfthreezzatmmachine extends Component {
  render() {
    return (
      <div>
      <div id="section-products" className="section-products my-md-4 py-md-4 my-5 py-5 bgshape">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 grow-left">
              <span className="text-uppercase d-block f-16">
              High Capacity Cash Dispensing ATM
              </span>
              <h3 className="f-36 text-gray">
              MX5300
              </h3>
              <div className="f-16 mt-3">
              Being the ideal solution for off-premise environments, the MX5300 offers an advanced design and includes a user-friendly interface displayed on a 15” screen. This full Windows model can handle whatever transaction set you can dream up. Its sophisticated look combined with its high cash capacity make the MX5300 series a fantastic fit for both Retail and FI installs.
              </div>
              <div className="mt-3 mt-md-3">
                <a href={mxfthreezzatmPDF} rel="noopener noreferrer" target="_blank" className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn">Brochure</a>
                <Link  className="d-inline-block f-18 view-btn" to="/products">View All ATMs  <img alt="arrow" src={arrowImg} /></Link>
              </div>
            </div>
            <div className="col-12 col-md-6 grow-right text-center">
              <img alt="" src={mxfthreezzatm} className="product-detail-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-specification my-3 py-3">
        <div className="container">
          <h2>Specifications</h2>
          <span className="sep"></span>
          <div className="spec-wrap">
            <div className="specs-single">
                <div className="specs-name"> Dimension:</div>
                <div className="specs-desc">
                  <p>Height: 55”<br /> Width: 18.1”<br /> Depth: 25.4”<br /> Weight: 617 lbs.</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Environmental Conditions:</div>
                <div className="specs-desc">
                  <p>Temperature: 32°F - 104°F ( 0°C - 40°C )<br /> Humidity: 25% - 85%</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Software:</div>
                <div className="specs-desc">
                  <p>Microsoft Windows® 7<br /> Windows 10 ready</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Customer Interface:</div>
                <div className="specs-desc">
                  <p>15” TFT LCD display<br /> Input Method - Touch screen<br /> PIN Pad – PCI compliant<br /> ADA Audio Guidance –earphone jack<br /> Card Reader – DIP Hybrid, EMV level 1, 2 compliant, anti-skimming</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Media Technology:</div>
                <div className="specs-desc">
                  <p>Dispense<br /> - Up to 4 cassettes<br /> - 2,000 notes per cassette</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Security:</div>
                <div className="specs-desc">
                  <p>Safe: UL 291 Level 1<br /> Alarm - Seismic + Heat<br /> Locking Device - Electronic Safe Lock, KABA Mas Cencon (optional)</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Servicing:</div>
                <div className="specs-desc">
                  <p>Front access</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Printers:</div>
                <div className="specs-desc"> 3.1" Graphical Thermal Receipt Printer</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Additional Information:</div>
                <div className="specs-desc">
                  <p>Communication - TCP/IP</p>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default mxfthreezzatmmachine;