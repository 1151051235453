import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../Styles/productstyle.scss";
import "../../Styles/productdetailstyle.scss";
import gtfzzatm from "../../assets/images/products/G2500.png";
import arrowImg from "../../assets/images/right-arrow.svg";

import gtfzzatmPDF from "../../assets/images/products/brochure/Genmega_G2500_Brochure.pdf";

class gtfzzatmmachine extends Component {
  render() {
    return (
      <div>
      <div id="section-products" className="section-products my-md-4 py-md-4 my-5 py-5 bgshape">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 grow-left">
              <span className="text-uppercase d-block f-16">
              Adding flexibility to your business
              </span>
              <h3 className="f-36 text-gray">
              G2500
              </h3>
              <div className="f-16 mt-3">
              The G2500 comes standard with an 8" high-resolution wide-screen LCD, which can be upgraded to a 10.2" touch screen. The receipt printer can also be upgraded to a 3" graphics-capable for custom branding or coupons. The integrated topper can also be paired with a selection of energy-efficient LED toppers to add visual impact and attract customers.
              </div>
              <div className="mt-3 mt-md-3">
                <a href={gtfzzatmPDF} rel="noopener noreferrer" target="_blank" className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn">Brochure</a>
                <Link  className="d-inline-block f-18 view-btn" to="/products">View All ATMs  <img alt="arrow" src={arrowImg} /></Link>
              </div>
            </div>
            <div className="col-12 col-md-6 grow-right text-center">
              <img alt="" src={gtfzzatm} className="product-detail-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-specification my-3 py-3">
        <div className="container">
          <h2>Specifications</h2>
          <span className="sep"></span>
          <div className="spec-wrap">
            <div className="specs-single">
                <div className="specs-name"> Dimension:</div>
                <div className="specs-desc">
                    <p>
                    Height = 56.3” <br />
                    Width = 15.8” <br />
                    Depth = 22.3” <br />
                    Weight = 222 lbs. (Without cash dispenser)
                    </p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Power Requirements:</div>
                <div className="specs-desc">
                    <p>110/220 VAC ±10%, 50/60 Hz, 145 Watts</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Temperature Requirements:</div>
                <div className="specs-desc"> In storage: 32°F - 104°F (0°C ~ 49°C) <br />While operating: 45°F - 95°F (5°C ~ 35°C)</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Allowable Humidity:</div>
                <div className="specs-desc">
                    <p>In storage : 10% &lt; RH &lt; 90%, non-condensed <br />
                    While operating : 15% &lt; RH &lt; 85%, non-condensed
                    </p>
                </div>
            </div>
            </div>
        </div>
      </div>
      </div>
    );
  }
}

export default gtfzzatmmachine;