import React, { Component } from "react";

//import optbgImg from "../assets/images/option-bg.png";
import optbgImg from "../assets/images/atm-image.jpg";
import arrowrightImg from "../assets/images/right-white-arrow.svg";

class chooseOptions extends Component {
  render() {
    return (
      <div className="section-choose-options">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 no-gutters p-0">
              <img className="img-fluid w-100 h-100" src={optbgImg} alt="" />
            </div>
            <div className="col-12 col-md-6 no-gutters bg-green text-white p-md-5 pb-4">
              <h3 className="pt-4 px-5">Many Options To Choose From</h3>
              <p className="pt-4 px-5">
                We’re constantly looking to add new options for our customers to
                choose from, so that we can make our services more accessible
                than ever before.
              </p>
              <p className="pt-4 px-5">
                With lease and cash replenishment options, you can rest assured
                knowing that Liberty Payment & Processing is your one-stop-shop
                for all of your ATM needs
              </p>
              <div className="mt-3 my-md-5 px-5 pb-md-5">
                <a href="/#section-footer" className="d-inline-block bg-transparent border-0 text-white f-18">
                  Contact Us <img alt="arrow" src={arrowrightImg} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default chooseOptions;
