import React, { Component } from "react";
import logoImg from "../assets/images/logo.png";
import toggImg from "../assets/images/menu.svg";
import "../Styles/header.scss";
import { HashLink as Link } from "react-router-hash-link";
//import { Link } from "react-router-dom";

class header extends Component {
  render() {
    return (
      <div className="section-header pt-md-2 pt-lg-2">
        <div className="container">
          <div className="row">
            <div className="col-12 navigation-bar">
              <nav className="navbar navbar-expand-lg py-0">
                <Link className="navbar-brand py-0" to="/">
                  <img className="img-fluid logo" src={logoImg} alt="" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                >
                  <img src={toggImg} alt="" />
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mx-auto my-0 align-self-end">
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        Home
                      </Link>
                    </li>
                    {/*
                    <li className="nav-item dropdown drop-arrow">
                      <Link
                        className="nav-link "
                        to="/services"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Services
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          ATM Installation
                        </a>
                        <a className="dropdown-item" href="#">
                          ATM Processing
                        </a>
                        <a className="dropdown-item" href="#">
                          ATM Repairs
                        </a>
                        <a className="dropdown-item" href="#">
                          Credit Card Processing
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item" href="#">
                          And More
                        </a>
                      </div>
                    </li>
                    */}
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/#section-pay-process"
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/products">
                        Products
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/faq">
                        FAQs
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/#section-footer">
                        Contact Us
                      </Link>
                    </li>

                    {/*
                    <li className="nav-item">
                      <Link className="nav-link" to="contactus">
                        Contact Us
                      </Link>
                    </li>
                    */}
                  </ul>
                  <div className="call-btn-main bg-orange d-inline-block mb-3 mb-lg-0">
                    <button className="text-left call-btn pr-5 py-2 d-inline-block">
                      Call us on
                      <span className="d-block">1 (833) - 333 - 5286</span>
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default header;
