import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../Styles/productstyle.scss";
import "../../Styles/productdetailstyle.scss";
import cszzzatm from "../../assets/images/products/C6000.png";
import arrowImg from "../../assets/images/right-arrow.svg";

import cszzzatmPDF from "../../assets/images/products/brochure/Genmega_C6000_Brochure.pdf";

class cszzzatmmachine extends Component {
  render() {
    return (
      <div>
      <div id="section-products" className="section-products my-md-4 py-md-4 my-5 py-5 bgshape">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 grow-left">
              <span className="text-uppercase d-block f-16">
              Retail ATM Evolution
              </span>
              <h3 className="f-36 text-gray">
              C6000
              </h3>
              <div className="f-16 mt-3">
              The Genmega C6000 ATM includes high-end features including a 15" LCD with function key or 15" touch screen LCD and 2K cassette, eye catching modern design and host of hardware options driven by a Microsoft Windows CE™ 6.0 operating environment for maximum flexibility.
              </div>
              <div className="mt-3 mt-md-3">
                <a href={cszzzatmPDF} rel="noopener noreferrer" target="_blank" className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn">Brochure</a>
                <Link  className="d-inline-block f-18 view-btn" to="/products">View All ATMs  <img alt="arrow" src={arrowImg} /></Link>
              </div>
            </div>
            <div className="col-12 col-md-6 grow-right text-center">
              <img alt="" src={cszzzatm} className="product-detail-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-specification my-3 py-3">
        <div className="container">
          <h2>Specifications</h2>
          <span className="sep"></span>
          <div className="spec-wrap">
            <div className="specs-single">
                <div className="specs-name"> Dimensions:</div>
                <div className="specs-desc">
                    <p>
                    Height = 56.0”<br/>
                    Width = 18.1”<br/>
                    Depth = 24.1”<br/>
                    Weight = 325 lbs. (Without cash dispenser)
                    </p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Power Requirements:</div>
                <div className="specs-desc">
                    <p>AC 110/220 V ±10%, 50/60 Hz, 145 Watts</p>
                </div>
            </div>
            </div>
        </div>
      </div>
      </div>
    );
  }
}

export default cszzzatmmachine;