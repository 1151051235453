import React, { Component } from "react";

class growBusiness extends Component {
  render() {
    return (
      <div className="section-grow-business my-5 py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 grow-left">
              <span className="text-uppercase d-block f-16">
                Grow your Business
              </span>
              <h3 className="f-36 text-gray">
                This Is All About Growing
                <span className="d-block">Your Business</span>
              </h3>
              <div className="we-focused f-14 mt-3">
                We Focused on <span className="d-block f-20 txt-grn">Our Expertise</span>
              </div>
            </div>
            <div className="col-12 col-md-6 grow-right">
              <p className="f-16">
                At Liberty Payment & Processing, we’re focused on lending our
                expertise, our resources, and our passion to help get our
                clients up and running quickly. From there, we implement the
                newest technologies in ATM software and related payment and
                processing solutions to ensure that our clients reduce their
                operating costs and bring in additional revenue.{" "}
              </p>
              <p className="f-16 pt-3">
                And we do it all 100% compliant with all government regulations,
                with reliable ATM processing, and with the number one customer
                service team in the industry – all for an affordable price.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default growBusiness;
