import React, { Component } from "react";
import PaymentProcess from "../components/paymentProcessing";
import GrowB from "../components/growBusiness";
import Liberty from "../components/chooseLiberty";
import Chooseopt from "../components/chooseOptions";
import "../Styles/home.scss";

import machineImg from "../assets/images/banner-img.png";
import arrowImg from "../assets/images/right-arrow.svg";

class home extends Component {
  render() {
    return (
      <div>
        <div className="section-banner mt-md-5 py-lg-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 banner-left">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="0"
                      className="active"
                    />
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="1"
                    />
                    {/*
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="2"
                    />
                    */}
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <h1 className="pb-3 f-28">
                        A New Kind of ATM Machine Company
                        <span className="d-block">
                          Let's Grow Your Business Together
                        </span>
                      </h1>
                      <p className="f-18 pt-3">
                        Liberty Payment & Processing is an ATM and credit
                        payment and processing company based in Burlington,
                        North California.
                      </p>
                      <div className="mt-3 mt-md-5">
                        <a
                          href="/#section-footer"
                          className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn"
                        >
                          Inquire Now
                        </a>
                        <a
                          href="/products"
                          className="d-inline-block f-18 view-btn"
                        >
                          View Details <img alt="arrow" src={arrowImg} />
                        </a>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <h1 className="pb-3 f-28">
                        At Liberty Payment &amp; Processing,
                        <span className="d-block">we’re focused on</span>
                      </h1>
                      <p className="f-18 pt-3">
                        lending our expertise, our resources, and our passion to
                        help get our clients up and running quickly.
                      </p>
                      <div className="mt-3 mt-md-5">
                        <a
                          href="/#section-footer"
                          className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn"
                        >
                          Inquire Now
                        </a>
                        <a
                          href="/#section-pay-process"
                          className="d-inline-block f-18 view-btn"
                        >
                          View Details <img src={arrowImg} alt="" />
                        </a>
                      </div>
                    </div>
                    {/*
                    <div className="carousel-item">
                      <h1 className="pb-3 f-28">
                        A New Kind of ATM Machine Company
                        <span className="d-block">
                          Let's Grow Your Business Together
                        </span>
                      </h1>
                      <p className="f-18 pt-3">
                        Liberty Payment & Processing is an ATM and credit
                        payment and processing company based in Burlington,
                        North California.
                      </p>
                      <div className="mt-3 mt-md-5">
                        <a
                          href="/#section-footer"
                          className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn"
                        >
                          Inquire Now
                        </a>
                        <a
                          href="/#section-footer"
                          className="d-inline-block f-18 view-btn"
                        >
                          View Details <img src={arrowImg} alt="" />
                        </a>
                      </div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 banner-right pb-3 pb-md-5">
                <img alt="atmMachine" className="img-fluid" src={machineImg} />
              </div>
            </div>
          </div>
        </div>
        <div id="section-pay-process">
          <PaymentProcess />
        </div>
        <div id="section-grow-business">
          <GrowB />
        </div>
        <Liberty />
        <Chooseopt />
      </div>
    );
  }
}

export default home;
