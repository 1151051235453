import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../Styles/productstyle.scss";
import "../../Styles/productdetailstyle.scss";
import forceatm from "../../assets/images/products/force-new-atm.png";
import arrowImg from "../../assets/images/right-arrow.svg";

import forceatmPDF from "../../assets/images/products/brochure/MX2800SE_FORCE-1.pdf";

class forceatmmachine extends Component {
  render() {
    return (
      <div>
      <div className="section-products my-md-4 py-md-4 my-5 py-5 bgshape">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 grow-left">
              <span className="text-uppercase d-block f-16">
                Designed By Retail, For Retail
              </span>
              <h3 className="f-36 text-gray">
                FORCE
              </h3>
              <div className="f-16 mt-3">
                Hyosung is dedicated to making technology work for the humans who use it. We provide solutions that simply work better and more intuitively. Every ATM product, service, and support system is engineered to be reliable and flexible. We listen to our customers and continually improve our offerings to reflect their input. We bring that innovation to banking. It is this commitment to human technology that has made us a dependable partner and global leader in the ever-changing world of banking.
              </div>
              <div className="mt-3 mt-md-3">
                <a href={forceatmPDF} rel="noopener noreferrer" target="_blank" className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn">Brochure</a>
                <Link  className="d-inline-block f-18 view-btn" to="/products">View All ATMs  <img alt="arrow" src={arrowImg} /></Link>
              </div>
            </div>
            <div className="col-12 col-md-6 grow-right text-center">
              <img alt="" src={forceatm} className="product-detail-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-specification my-3 py-3">
        <div className="container">
          <h2>Specifications</h2>
          <span className="sep"></span>
          <div className="spec-wrap"> 
          <div className="specs-single"> 
            <div className="specs-name"> Dimension:</div>
            <div className="specs-desc"> 
              <p>Height: 56.4”<br /> Width: 15.7”<br /> Depth: 23.4”<br /> Weight: 286.6 lbs.</p>
            </div>
          </div>
          <div className="specs-single"> 
            <div className="specs-name"> Environmental Conditions:</div>
            <div className="specs-desc"> 
              <p>Temperature: 0°C ~ 40°C ( 32°F ~ 104°F )<br /> Humidity: 20%-85%</p>
            </div>
          </div>
          <div className="specs-single"> 
            <div className="specs-name"> System Platform:</div>
            <div className="specs-desc"> Microsoft® Window® CE6.0</div>
          </div>
          <div className="specs-single">
            <div className="specs-name"> Security:</div>
            <div className="specs-desc"> <p>UL 291 Business Hours Safe</p></div>
          </div>
          <div className="specs-single"> 
            <div className="specs-name"> Printers:</div>
            <div className="specs-desc"> 80mm Graphical thermal receipt printer</div>
          </div>
          <div className="specs-single">
            <div className="specs-name"> Communication:</div>
            <div className="specs-desc"> TCP/IP, Wireless, Dial-up</div>
          </div>
          <div className="specs-single">
            <div className="specs-name"> Screen Display:</div>
            <div className="specs-desc"> 12.1” TFT LCD, Privacy filter (optional)</div>
          </div>
          <div className="specs-single">
            <div className="specs-name"> Input Type:</div>
            <div className="specs-desc"> 4x2 Touch Function Keys, PCI compliant EPP, T-DES, DES, RSA</div>
          </div><div className="specs-single"> <div className="specs-name"> Card Reader:</div><div className="specs-desc"> Dip Hybrid Card Reader, EMV Level 1,2 Compliant, Anti-skimming (optional)</div></div><div className="specs-single"> <div className="specs-name"> Cash Dispenser:</div><div className="specs-desc"> 1,000 Notes Cassette, 2,000 note removable cassette upgradable to three cassettes</div></div><div className="specs-single"> <div className="specs-name"> Power Supply:</div><div className="specs-desc"> AC 110-240V, 50-60 Hz</div></div><div className="specs-single"> <div className="specs-name"> Additional Features:</div><div className="specs-desc"> <p>Lead-through Indicator, Audio Jack, Speaker, NFC (optional)</p></div></div></div>
        </div>
      </div>
      </div>
    );
  }
}

export default forceatmmachine;