import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../Styles/productstyle.scss";
import "../../Styles/productdetailstyle.scss";
import gttzzzatm from "../../assets/images/products/GT3000.png";
import arrowImg from "../../assets/images/right-arrow.svg";

import gttzzzatmPDF from "../../assets/images/products/brochure/Genmega_GT3000_Brochure.pdf";

class gttzzzatmmachine extends Component {
  render() {
    return (
      <div>
      <div id="section-products" className="section-products my-md-4 py-md-4 my-5 py-5 bgshape">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 grow-left">
              <span className="text-uppercase d-block f-16">
              Pursuing ultimate versatility of installation
              </span>
              <h3 className="f-36 text-gray">
              GT3000
              </h3>
              <div className="f-16 mt-3">
              The GT3000 uses a 7" high-resolution wide-screen LCD, 2" receipt printer and can be ordered with a fixed cassette or rear-load removalble cassette dispenser. There is also an optional Rear service-panel to provide secure loading and management fuctions.
              </div>
              <div className="mt-3 mt-md-3">
                <a href={gttzzzatmPDF} rel="noopener noreferrer" target="_blank" className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn">Brochure</a>
                <Link  className="d-inline-block f-18 view-btn" to="/products">View All ATMs  <img alt="arrow" src={arrowImg} /></Link>
              </div>
            </div>
            <div className="col-12 col-md-6 grow-right text-center">
              <img alt="" src={gttzzzatm} className="product-detail-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-specification my-3 py-3">
        <div className="container">
          <h2>Specifications</h2>
          <span className="sep"></span>
          <div className="spec-wrap">
            <div className="specs-single">
                <div className="specs-name"> Dimensions:</div>
                <div className="specs-desc">
                    <p>
                        Height = 35.8”<br/>
                        Width = 15”<br/>
                        Depth = 30”<br/>
                        Weight = 210 lbs.(Without cash dispenser)
                    </p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Power Requirements:</div>
                <div className="specs-desc">
                    <p>110/220 VAC ±10%, 50/60 Hz, 145 Watts</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Temperature Requirements:</div>
                <div className="specs-desc"> 
                In storage: 32°F - 104°F (0°C ~ 49°C)<br/>
                While operating: 45°F - 95°F (5°C ~ 35°C)
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Allowable Humidity:</div>
                <div className="specs-desc">
                    <p>
                    In storage : 10% &lt; RH &lt; 90%, non-condensed<br/>
                    While operating : 15% &lt; RH &lt; 85%, non-condensed
                    </p>
                </div>
            </div>
            </div>
        </div>
      </div>
      </div>
    );
  }
}

export default gttzzzatmmachine;