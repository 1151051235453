import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../Styles/productstyle.scss";
import "../../Styles/productdetailstyle.scss";
import ftzzseatm from "../../assets/images/products/5200SE.png";
import arrowImg from "../../assets/images/right-arrow.svg";

import ftzzseatmPDF from "../../assets/images/products/brochure/5200SE-2-2.pdf";

class ftzzseatmmachine extends Component {
  render() {
    return (
      <div>
      <div className="section-products my-md-4 py-md-4 my-5 py-5 bgshape">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 grow-left">
              <span className="text-uppercase d-block f-16">
              The Most Flexible Solution For High-Volume, High Value Transactions
              </span>
              <h3 className="f-36 text-gray">
              5200SE
              </h3>
              <div className="f-16 mt-3">
              Hyosung is proud to introduce the latest addition to the retail family - the 5200SE. The 5200SE delivers maximum service and security for retail locations with large revenue potential, combining the ease-of-use and maintainability of a traditional retail ATM with the advanced functions and security of a financial institution cash dispenser.
              </div>
              <div className="mt-3 mt-md-3">
                <a href={ftzzseatmPDF} rel="noopener noreferrer" target="_blank" className="d-inline-block f-18 mr-3 bg-orange text-white inquire-btn">Brochure</a>
                <Link  className="d-inline-block f-18 view-btn" to="/products">View All ATMs  <img alt="arrow" src={arrowImg} /></Link>
              </div>
            </div>
            <div className="col-12 col-md-6 grow-right text-center">
              <img alt="" src={ftzzseatm} className="product-detail-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-specification my-3 py-3">
        <div className="container">
          <h2>Specifications</h2>
          <span className="sep"></span>
          <div className="spec-wrap">
            <div className="specs-single">
                <div className="specs-name"> Dimension:</div>
                <div className="specs-desc">
                    <p>Height: 55.4”<br /> Width: 15.7”<br /> Depth: 22.8”<br /> Weight: 287 lbs.</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Environmental Conditions:</div>
                <div className="specs-desc">
                    <p>Temperature: 0°C ~ 40°C ( 32°F ~ 104°F )<br /> Humidity: 20%-85%</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> System Platform:</div>
                <div className="specs-desc"> Microsoft® Window® CE6.0</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Security:</div>
                <div className="specs-desc">
                    <p>UL 291 Business Hours Safe, Electronic Lock, KABA Mas Cencon Lock, Level1 Safe, Cencon Lock, S&amp;G Lock</p>
                </div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Printers:</div>
                <div className="specs-desc"> Electronic journal, Thermal line 3” Receipt printer</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Communication:</div>
                <div className="specs-desc"> TCP/IP, Dial-up</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Screen Display:</div>
                <div className="specs-desc"> 12.1” TFT LCD</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Input Type:</div>
                <div className="specs-desc"> Option RKT, ADA Compliant, 8 function keys, PCI compliant EPP, touch screen</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Card Reader:</div>
                <div className="specs-desc"> Dip type card reader, EMV card reader</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Cash Dispenser:</div>
                <div className="specs-desc"> 1,000 note removable cassette, 2,000 note removable cassette, Upgradable to 3 cassettes</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Power Supply:</div>
                <div className="specs-desc"> AC 110-240V, 50-60 Hz</div>
            </div>
            <div className="specs-single">
                <div className="specs-name"> Additional Features:</div>
                <div className="specs-desc">
                    <p>Lead-through Indicator, Supports six languages: English, French, Japanese, Chinese, and Korean, Digital Receipt, Enhanced Standard 1 (Hyosung Standard), DCC (Dynamic Currency Conversion), Earphone jack, NFC (optional), TLS Protocol communication, Decimal surcharge, Donation transaction, NDC Emulation (Windows 7 only)</p>
                </div>
            </div>
            </div>      </div>
        </div>
      </div>
      
    );
  }
}

export default ftzzseatmmachine;